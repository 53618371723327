@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Daxline Pro';
  src: local('Daxline Pro'), url(./assets/daxline-pro.ttf) format('truetype');
}

@layer base {
  html {
    font-family: Daxline Pro, system-ui, sans-serif;
  }
}